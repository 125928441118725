<template>
<div style="text-align: left;">
<!--  支付方式-->
  <h1 style="text-align: center">{{this.$t('Payment.method')}}</h1>
  <h2>{{this.$t('Payment.method.1')}}</h2>
  <h3>{{this.$t('Payment.method.2')}}</h3>
  <h3>{{this.$t('Payment.method.3')}}</h3>
  <p>{{this.$t('Payment.method.4')}}</p>
  <h3>{{this.$t('Payment.method.5')}}</h3>
  <p>{{this.$t('Payment.method.6')}}</p>
  <p>{{this.$t('Payment.method.7')}}</p>
  <p>{{this.$t('Payment.method.8')}}</p>
  <p>{{this.$t('Payment.method.9')}}</p>
  <p>{{this.$t('Payment.method.10')}}</p>
  <p>{{this.$t('Payment.method.11')}}</p>
  <p>{{this.$t('Payment.method.12')}}</p>
  <h3>{{this.$t('Payment.method.13')}}</h3>
  <p>{{this.$t('Payment.method.14')}}</p>
  <h3>{{this.$t('Payment.method.15')}}</h3>
  <p>{{this.$t('Payment.method.16')}}</p>
  <div style="position:relative;width: 100%;height: 200px">
    <div  style="position:absolute;top: 0;left: 0;right: 0;bottom: 0;margin: auto;width: 375px;height: 141px;">
      <img style="width: 100%;height: 100%"  :src="require('@/assets/images/paymentmethod.png')">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "PaymentMethod"
}
</script>

<style scoped>

</style>